<template>
  <div class="won-user-cell">
    <p v-if="isProcessing" class="won-user-cell__processing">
      {{ $t('PROCESSING_MSG') }}
    </p>

    <p v-else-if="isStubShown" class="cells-cmn__str cells-cmn__str_sec">
      {{ $t('COMMON.SYMBOLS.MDASH') }}
    </p>

    <template v-else>
      <div
        v-if="displayedUser.id || isLookingForWinner"
        class="cells-cmn__cell"
      >
        <div class="cells-cmn__str cells-cmn__str_nb won-user-cell__new_link">
          <component
            :is="isDealerLinkShown ? 'router-link' : 'p'"
            :to="{
              name: 'extension.copart.dealers.entry',
              params: { dealerId: displayedUser.accountId }
            }"
            class="cells-cmn__str cells-cmn__str_nb won-user-cell__new_link"
            :title="displayedName"
          >
            {{ displayedName }}
          </component>
        </div>
      </div>

      <dealer-select
        v-if="isDealerSelectShown"
        class="won-user-cell__select"
        value=""
        :pinned-options="dealerPinnedOptions"
        @update-user="updateWinner"
      />

      <component
        v-if="item.buyerNumber"
        :is="$can($USER_CLAIMS.EXTENSION_ACCOUNTS) ? 'router-link' : 'p'"
        :to="{
          name: 'extension.copart.accounts.entry',
          query: { search: item.buyerNumber }
        }"
        class="cells-cmn__str cells-cmn__str_nb cells-cmn__str_sec"
        :class="{
          'cells-cmn__str_link': $can($USER_CLAIMS.EXTENSION_ACCOUNTS)
        }"
        :title="item.buyerNumber"
      >
        {{ item.buyerNumber }}
      </component>

      <div class="won-user-cell__hint" v-if="isUserHint">
        This record only synchronizes the bid record in Copart and does not
        bidthrough Extension.
      </div>
    </template>
  </div>
</template>

<script>
import { EXTENSION_WON_LIST_TEMPLATES } from '../constants'
import DealerSelect from 'Common/DealerSelect'

import { mapActions, mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'
import { extensionWonActions } from '../store/types'

import { CopartWinner } from 'Models/CopartWinner'
import { showError } from 'Utils/notifications'

export default {
  name: 'won-user-cell',
  components: { DealerSelect },

  props: {
    item: {
      type: CopartWinner,
      required: true
    },

    template: {
      type: String,
      default: EXTENSION_WON_LIST_TEMPLATES.won,
      validator(value) {
        return Object.values(EXTENSION_WON_LIST_TEMPLATES).includes(value)
      }
    }
  },

  data() {
    return {
      displayedUser: {},
      isLookingForWinner: false,
      isProcessing: false
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      platformId: userGetters.PLATFORM_ID
    }),

    isStubShown() {
      return (
        !this.displayedUser.id &&
        this.template === EXTENSION_WON_LIST_TEMPLATES.history
      )
    },
    isUserHint() {
      return (
        !this.displayedUser.id &&
        location.pathname === '/extension/copart/won/won'
      )
    },

    isDealerSelectShown() {
      return (
        this.item.vehicle.id &&
        (!this.displayedUser.id || this.isLookingForWinner)
      )
    },

    isDealerLinkShown() {
      return (
        this.displayedUser.accountId !== this.platformId &&
        this.$can(this.$USER_CLAIMS.EXTENSION_DEALERS)
      )
    },

    displayedName() {
      switch (true) {
        case this.isLookingForWinner:
          return this.$t('LOOKING_FOR_WINNER_MSG')

        case this.displayedUser.accountId === this.platformId:
          return this.$t('DIRECT_PURCHASE_MSG')

        default:
          return this.displayedUser.fullName
      }
    },

    dealerPinnedOptions() {
      return [
        {
          id: '',
          fullName: this.$t('LOOKING_FOR_WINNER_MSG'),
          lookingForWinner: true
        },
        {
          id: this.platformId,
          fullName: this.$t('DIRECT_PURCHASE_MSG')
        }
      ]
    }
  },

  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(val) {
        this.displayedUser = val.participation
        this.isLookingForWinner = val.lookingForWinner
      }
    }
  },

  methods: {
    ...mapActions('ui/extension-won', {
      updateLotSale: extensionWonActions.UPDATE_LOT_SALE
    }),

    async updateWinner(user) {
      this.isProcessing = true

      try {
        const lotSaleId = this.item.lotSaleId || this.item.id
        await this.updateLotSale({
          lotSaleId,
          participationId: user.id ? `${lotSaleId}:${user.id}` : ''
        })

        this.displayedUser = user
        this.isLookingForWinner = Boolean(user.lookingForWinner)
      } catch (err) {
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
        console.error(err)
      } finally {
        this.isProcessing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/cells/cells.scss';

.won-user-cell {
  text-decoration: none;
  display: grid;
  &__new_link {
    text-decoration: underline;
    color: #094de4;
  }
  &__hint {
    position: absolute;
    bottom: 10px;
    color: #fd5656;
  }
  &__select {
    max-width: 13em;

    /deep/ .ui-dropdown {
      &__trigger {
        padding: 0.5em 2em 0.5em 1em;

        &_select-like[fill='frame'][look='default'] {
          border-color: $color-ui-secondary;
          color: $color-ui-secondary;
        }

        &-caret {
          width: 2.6em;
          height: 2.6em;
        }
      }

      &__content {
        max-height: 40em;
        overflow: auto;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "PROCESSING_MSG": "Processing…",
    "LOOKING_FOR_WINNER_MSG": "Looking For Winner",
    "DIRECT_PURCHASE_MSG": "Direct Purchase"
  },
  "ka": {
    "PROCESSING_MSG": "მუშავდება…",
    "LOOKING_FOR_WINNER_MSG": "დაკარგული",
    "DIRECT_PURCHASE_MSG": "პირდაპირ ნაყიდი"
  },
  "ru": {
    "PROCESSING_MSG": "Обработка…",
    "LOOKING_FOR_WINNER_MSG": "Поиск победителя",
    "DIRECT_PURCHASE_MSG": "Прямая покупка"
  },
  "uk": {
    "PROCESSING_MSG": "Обробка…",
    "LOOKING_FOR_WINNER_MSG": "Пошук переможця",
    "DIRECT_PURCHASE_MSG": "Пряма покупка"
  }
}
</i18n>
