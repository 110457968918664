<template>
  <div class="won-car-cell cells-cmn__cell">
    <div class="won-car-cell__link won-car-cell__icon-wrp">
      <ui-icon class="won-car-cell__icon" icon="car" />
    </div>

    <div class="cells-cmn__cell-col">
      <span
        class="
          won-car-cell__name
          cells-cmn__str
          cells-cmn__str_nb
        "
        :title="vehicle.name"
      >
        {{ vehicle.name }}
      </span>

      <span
        v-if="vehicle.id"
        class="won-car-cell__desc cells-cmn__str cells-cmn__str_sec"
      >
        <span>{{ $t('ID_FORMAT', { id: vehicle.id }) }}</span>

        <ui-copy-button
          :value="vehicle.id"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>

      <span
        v-if="vehicle.vin"
        class="won-car-cell__desc cells-cmn__str cells-cmn__str_sec"
      >
        <span>{{ $t('VIN_FORMAT', { vin: vehicle.vin }) }}</span>

        <ui-copy-button
          :value="vehicle.vin"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { UiIcon, UiCopyButton } from '@shelf.network/ui-kit'
import { ExtensionVehicle } from 'Models/ExtensionVehicle'

export default {
  name: 'won-car-cell',
  components: {
    UiIcon,
    UiCopyButton
  },

  props: {
    vehicle: { type: ExtensionVehicle, required: true }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/cells/cells.scss';

.won-car-cell {
  &__icon-wrp {
    margin-right: 0.9em;
    height: 5.2em;
    max-width: 6.4em;
    min-width: 6.4em;
    border-radius: 5px;
    background-color: $color-light-grey;
    display: grid;
    place-items: center;
  }

  &__icon {
    font-size: 2.4em;
    color: $color-dark-grey;
  }

  &__name {
    text-decoration: none;
    color: $color-dark;
    font-size: 1.1em;
  }

  &__desc {
    font-size: 0.9em;
  }

  &__location {
    font-size: 0.7em;
    line-height: 1.5;
    font-weight: 500;
  }
}
</style>

<i18n>
{
  "en": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ka": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ru": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "uk": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  }
}
</i18n>
