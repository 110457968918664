<template>
  <div class="won-car-cell cells-cmn__cell">
    <component
      :is="vehicle.id ? 'a' : 'div'"
      class="won-car-cell__link won-car-cell__img-wrp"
      :href="lotLink"
      target="_blank"
      rel="nofollow noopener"
    >
      <ui-img
        class="won-car-cell__img"
        v-bind="lazyVehicleImg"
        :title="lotName"
        :alt="vehicle.id"
      />
    </component>

    <div class="cells-cmn__cell-col">
      <component
        :is="vehicle.id ? 'a' : 'span'"
        class="
          won-car-cell__name
          cells-cmn__str
          cells-cmn__str_nb
        "
        :href="lotLink"
        target="_blank"
        rel="nofollow noopener"
        :title="lotName"
      >
        {{ lotName }}
      </component>

      <span
        v-if="vehicle.id"
        class="won-car-cell__desc cells-cmn__str cells-cmn__str_sec"
      >
        <span>{{ $t('ID_FORMAT', { id: vehicle.id }) }}</span>

        <ui-copy-button
          :value="vehicle.id"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>

      <span
        v-if="vehicle.vin"
        class="won-car-cell__desc cells-cmn__str cells-cmn__str_sec"
      >
        <span>{{ $t('VIN_FORMAT', { vin: vehicle.vin }) }}</span>

        <ui-copy-button
          :value="vehicle.vin"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { UiImg, UiCopyButton } from '@shelf.network/ui-kit'

import { EXTENSION_WON_LIST_TEMPLATES } from '../constants'
import { CopartWinner } from 'Models/CopartWinner'

import { lazyTinyImg } from 'Utils/generateImageProps'
import placeholderPath from 'Assets/icons/placeholder.svg'

export default {
  name: 'won-car-cell',
  components: {
    UiImg,
    UiCopyButton
  },

  props: {
    item: {
      type: CopartWinner,
      required: true
    },
    template: {
      type: String,
      default: EXTENSION_WON_LIST_TEMPLATES.won,
      validator(value) {
        return Object.values(EXTENSION_WON_LIST_TEMPLATES).includes(value)
      }
    }
  },

  computed: {
    vehicle() {
      return this.item.vehicle
    },

    lazyVehicleImg() {
      return this.vehicle.imageUrl
        ? lazyTinyImg(this.vehicle.imageUrl, true)
        : { src: placeholderPath }
    },

    lotName() {
      return this.item.description || this.vehicle.name
    },

    lotLink() {
      return `https://www.copart.com/lot/${this.vehicle.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/cells/cells.scss';

.won-car-cell {
  &__img-wrp {
    margin-right: 0.9em;
  }

  &__img {
    --ui-img-border-radius: 5px;

    display: block;
    height: 5.2em;
    max-width: 6.4em;
    min-width: 6.4em;
  }

  &__name {
    text-decoration: underline;
    color: #094de4;
    font-size: 1.1em;
  }

  &__desc {
    font-size: 0.9em;
  }

  &__location {
    font-size: 0.7em;
    line-height: 1.5;
    font-weight: 500;
  }
}
</style>

<i18n>
{
  "en": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ka": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ru": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "uk": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  }
}
</i18n>
