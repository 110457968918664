var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"won-user-cell"},[(_vm.isProcessing)?_c('p',{staticClass:"won-user-cell__processing"},[_vm._v("\n    "+_vm._s(_vm.$t('PROCESSING_MSG'))+"\n  ")]):(_vm.isStubShown)?_c('p',{staticClass:"cells-cmn__str cells-cmn__str_sec"},[_vm._v("\n    "+_vm._s(_vm.$t('COMMON.SYMBOLS.MDASH'))+"\n  ")]):[(_vm.displayedUser.id || _vm.isLookingForWinner)?_c('div',{staticClass:"cells-cmn__cell"},[_c('div',{staticClass:"cells-cmn__str cells-cmn__str_nb won-user-cell__new_link"},[_c(_vm.isDealerLinkShown ? 'router-link' : 'p',{tag:"component",staticClass:"cells-cmn__str cells-cmn__str_nb won-user-cell__new_link",attrs:{"to":{
            name: 'extension.copart.dealers.entry',
            params: { dealerId: _vm.displayedUser.accountId }
          },"title":_vm.displayedName}},[_vm._v("\n          "+_vm._s(_vm.displayedName)+"\n        ")])],1)]):_vm._e(),_vm._v(" "),(_vm.isDealerSelectShown)?_c('dealer-select',{staticClass:"won-user-cell__select",attrs:{"value":"","pinned-options":_vm.dealerPinnedOptions},on:{"update-user":_vm.updateWinner}}):_vm._e(),_vm._v(" "),(_vm.item.buyerNumber)?_c(_vm.$can(_vm.$USER_CLAIMS.EXTENSION_ACCOUNTS) ? 'router-link' : 'p',{tag:"component",staticClass:"cells-cmn__str cells-cmn__str_nb cells-cmn__str_sec",class:{
        'cells-cmn__str_link': _vm.$can(_vm.$USER_CLAIMS.EXTENSION_ACCOUNTS)
      },attrs:{"to":{
        name: 'extension.copart.accounts.entry',
        query: { search: _vm.item.buyerNumber }
      },"title":_vm.item.buyerNumber}},[_vm._v("\n      "+_vm._s(_vm.item.buyerNumber)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isUserHint)?_c('div',{staticClass:"won-user-cell__hint"},[_vm._v("\n      This record only synchronizes the bid record in Copart and does not\n      bidthrough Extension.\n    ")]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }